@use '@carbon/colors';
@use '@carbon/layout';

.previous-chats-container {
  min-height: 60vh;
  height: 60vh;
  max-height: 60vh;
  overflow: auto;
  a {
    text-decoration: none;
    color: black;
    p {
      box-sizing: border-box;
      border-radius: layout.$spacing-02;
      padding: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        background-color: colors.$gray-20;
        cursor: pointer;
      }
    }
  }
}

.active-chat {
  p {
    background-color: rgba(244, 146, 19,0.3);
  }

 
}
