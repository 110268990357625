@import './colors';

.btn-primary {
  background-color: $color-primary;
}

.btn-tertiary {
  color: $color-primary;
  border-color: $color-primary;
}
