.raised-logo {
  display: flex;
  background-color: white;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  img,
  svg {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12.5%;
    height: 65%;
  }
}
