@use '@carbon/colors';
@use '@carbon/layout';
@use '@carbon/type';

.title-container {
  display: flex;
  margin-top: layout.$spacing-05;
  margin-bottom: layout.$spacing-07;
  .logo {
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    max-width: 9rem;
  }
}

.login-container-wrapper {
  position: relative;
  .login-container {
    background: colors.$gray-10;
    padding-top: layout.$spacing-06;
    padding-bottom: layout.$spacing-07;
  }

  .logo-right {
    position: absolute;
    top: -3.5rem;
    right: -2.5rem;
    width: 10rem;
    z-index: -1;
  }

  .logo-left {
    position: absolute;
    bottom: 2rem;
    left: -3rem;
    width: 10rem;
    z-index: -1;
  }

  button[type='submit'] {
    margin-top: layout.$spacing-07;
    margin-bottom: layout.$spacing-07;
  }

  h1 {
    font-size: type.type-scale(5) !important;
  }

  h2 {
    font-size: type.type-scale(4) !important;
  }
}

@include type.reset();
@include type.default-type();
@include type.type-classes();
