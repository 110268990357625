@import '../../styles/colors.scss';

.search-form {
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
}

.project-finder-container {
  max-width: min(80ch, 90%);
  margin: 2rem auto;

  .cds--accordion__item {
    border-block-end: none !important;
    svg {
      float: right;
    }

    .cds--accordion__title {
      color: $color-warning;
    }

    .cds--accordion__content {
      padding-inline-end: 2rem;
    }
  }

  .recent-project-searches-container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    .recent-project-searches-item {
      border-bottom: 1px solid $color-lines;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;
      color: rgba(0, 0, 0, 0.7);

      &:last-of-type {
        border-bottom: none;
      }

      span:nth-of-type(2) {
        float: right;
      }
    }
  }
}
