@import '~carbon-components-react/scss/grid';
@import './styles/overrides';
@import '~carbon-components-react/scss/fonts';

@import '~carbon-components-react/scss/components/modal';
@import '~carbon-components-react/scss/components/checkbox';
@import '~carbon-components-react/scss/components/stack';
@import '~carbon-components-react/scss/components/text-input';
@import '~carbon-components-react/scss/components/button';
@import '~carbon-components-react/scss/components/search';
@import '~carbon-components-react/scss/components/loading';
@import '~carbon-components-react/scss/components/multiselect';
@import '~carbon-components-react/scss/components/select';
@import '~carbon-components-react/scss/components/pagination';
@import '~carbon-components-react/scss/components/breadcrumb';
@import '~carbon-components-react/scss/components/notification';

@import './styles/colors';
@import './styles/buttons';
@import './styles/spacing';
@import './styles/links';
@import './styles/display';
@import './styles/sizing';

body {
  margin: 0;
}
