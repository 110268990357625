.project-search-container {
  width: min(80ch, 90%);
  margin: 0 auto;
}

.search-form {
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: flex-end;
}

.project-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  margin-top: 2rem;
}
