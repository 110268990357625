.modal-example {
  inline-size: 140vw;
  margin-left: -20vw;
  div[role='dialog'] {
    > div {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
