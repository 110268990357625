@use '@carbon/layout';

.project {
  border: 1px solid black;
  border-radius: layout.$spacing-02;
  padding: layout.$spacing-07;

  a:hover {
    background-color: unset;
  }
}

.project-details {
  margin-top: layout.$spacing-06;
  padding: layout.$spacing-02;

  .project-description {
    display: flex;
    gap: 1rem;
  }

  .toast-project-documents {
    p {
      font-size: 1.1rem;
      margin-top: 3px;
      margin-bottom: 3px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
