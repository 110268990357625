.link-button{
  text-decoration: underline;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: left;
  font-size:1rem;
  font-weight:400;
  cursor: pointer;
  font-family: inherit;
}