@use '@carbon/colors';
@use '@carbon/type';
@import '../../styles/colors';

.side-bar {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-right: 1px solid $color-lines;
  position: sticky;
  height: 100vh;
  top: 0;
  background: transparent;
  grid-area: sidebar;
  padding: 0.75rem 1rem;
  gap: 1rem;
  min-width: fit-content;

  @media only screen and (max-width: 40em) {
    width: 33dvw;
  }

  /* Medium devices such as tablets (768px and up) */
  @media only screen and (min-width: 40em) {
    width: 20dvw;
  }

  /* Large devices such as laptops (1024px and up) */
  @media only screen and (min-width: 64em) {
    width: 20dvw;
  }

  .padding {
    flex-grow: 1;
  }

  .side-bar-toggle {
    background-color: colors.$gray-20;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 3rem);
    right: -20px;
    width: 40px;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  &.closed {
    width: 0;
    padding: 0;

    :not(.side-bar-toggle, svg, path) {
      display: none;
    }

    .side-bar-toggle {
      right: -40px;
    }

    .user-name {
      display: none;
    }
  }

  .user-name {
    padding-right: 10px;
    position: relative;
    margin-bottom: 4rem;
    button {
      background-color: $color-warning;
      border-radius: 50%;
      border: none;
      width: 40px;
      height: 40px;
    }

    span {
      font-size: type.type-scale(4);
      line-height: 40px;
    }
  }
}
