.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-right {
  justify-content: right;
}
