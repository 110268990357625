@use '@carbon/layout';
@use '@carbon/colors';

.cds--m-0 {
  margin: 0;
}

.cds--mt-03 {
  margin-top: layout.$spacing-03;
}

.cds--mt-04 {
  margin-top: layout.$spacing-04;
}

.cds--mt-05 {
  margin-top: layout.$spacing-05;
}

.cds--mt-06 {
  margin-top: layout.$spacing-06;
}

.cds--mt-07 {
  margin-top: layout.$spacing-07;
}

.cds--mt-10 {
  margin-top: layout.$spacing-10;
}

.cds--mb-03 {
  margin-bottom: layout.$spacing-03;
}

.cds--mb-04 {
  margin-bottom: layout.$spacing-04;
}

.cds--mb-05 {
  margin-bottom: layout.$spacing-05;
}

.cds--mb-07 {
  margin-bottom: layout.$spacing-07;
}

.cds--mb-10 {
  margin-bottom: layout.$spacing-10;
}

.cds--ml-auto {
  margin-left: auto;
}

.cds--ml-04 {
  margin-left: layout.$spacing-04;
}

.cds--mr-04 {
  margin-right: layout.$spacing-04;
}

.cds--p-05 {
  padding: layout.$spacing-05;
}

.cds--pt-04 {
  padding-top: layout.$spacing-04;
}

.cds--pt-07 {
  padding-top: layout.$spacing-07;
}

.cds--pt-10 {
  padding-top: layout.$spacing-10;
}

.cds--pb-04 {
  padding-bottom: layout.$spacing-04;
}

.cds--pb-07 {
  padding-bottom: layout.$spacing-07;
}

.cds--pb-10 {
  padding-bottom: layout.$spacing-10;
}

.cds--pl-0 {
  padding-left: 0;
}

.cds--pl-04 {
  padding-left: layout.$spacing-04;
}

.cds--pr-0 {
  padding-right: 0;
}

.cds--pr-04 {
  padding-right: layout.$spacing-04;
}

.cds--pr-05 {
  padding-right: layout.$spacing-05;
}

.divider {
  height: 1px;
  background-color: colors.$gray-20;
}
