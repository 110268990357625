.project {
  background-color: #eefbff;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.project-lhs {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.copyBtn {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #c6c6c6;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
}

.copyBtn:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.1);
}
