@use '@carbon/colors';
@use '@carbon/type';
@import '../../styles/colors';

.user-name {
  padding-right: 10px;
  position: relative;
  display: flex;
  align-content: center;

  button {
    background-color: $color-warning;
    border-radius: 50%;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0 !important ;
  }

  span {
    font-size: type.type-scale(4);
  }
}
