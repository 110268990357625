.filter-bar-wrapper {
  .filter-bar {
    display: flex;
    flex-direction: column;
    gap:4px;

    ul {
      list-style: none;
      padding-left: 0;
      position: sticky;
    }

    .cds--text-input {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }

    .cds--multi-select--filterable__wrapper {
      .cds--label {
        white-space: nowrap;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
  .filter-bar-divider {
    margin-top: -570px;
  }
}
