.logoContainer {
  min-width: fit-content;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 40em) {
  .logoContainer {
    width: 33dvw;
  }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 40em) {
  .logoContainer {
    width: 20dvw;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
  .logoContainer {
    width: 20dvw;
  }
}
