@use '@carbon/colors';

.header-container {
  overflow: visible;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  grid-area: header;
  background-color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  img {
    float: left;
  }

  .logout-btn {
    color: colors.$gray-50;
    cursor: pointer;
    float: right;
  }

  .action-bar {
    align-self: flex-end;
    display: flex;
    align-items: center;

    button {
      padding-right: 15px;
    }

    span {
      line-height: 48px;
      height: 48px;
    }

    .cds--popover-content {
      background-color: white;
      border-radius: 8px;
      height: auto;

      a {
        text-decoration: none;
      }
    }
  }
}

.tabs {
  display: flex;
  gap: 1rem;
  justify-items: flex-end;
  align-self: flex-end;
  align-items: flex-start;
}

.nav-tab {
  text-decoration: none;
  font-size: 16px;
  color: black;
  padding-bottom: 8px;
  margin-bottom: 4px;
  &.active {
    border-bottom: 3px solid colors.$blue-50;
  }
}

.popover-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
