@use '@carbon/colors';

$color-primary: #204ace;
$color-primary-active: #0050e6;
$color-secondary: #8298ad;
$color-warning: #f49213;
$color-lines: rgba(0, 0, 0, 0.1);
$color-dark-gray: rgba(0, 0, 0, 0.7);

.text-primary {
  color: $color-primary !important;
}

.text-secondary {
  color: $color-secondary !important;
}

.text-dark-gray {
  color: $color-dark-gray !important;
}

.text-warning {
  color: $color-warning !important;
}

.cds--bg-gray-10-lighten {
  background-color: lighten(colors.$gray-10, 2%);
}
