.chat-message {
  background-color: white;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  > div {
    &:nth-of-type(2) {
      width: 90%;
    }
  }

  .project-description {
    font-size: 1rem;
  }

  .cds--modal-content {
    padding-top: 1.8rem;
  }
}
