@use '@carbon/layout';
@use '@carbon/colors';
@import '../../styles/colors.scss';

.chat-container {
  box-sizing: border-box;
  width: 100%;

  .chat-msg-example {
    background-color: white;
    border: 1px solid $color-dark-gray;
    border-radius: layout.$spacing-03;
    color: colors.$gray-80;
    display: block;
    padding: layout.$spacing-04;

    &:hover {
      cursor: pointer;
      background-color: $color-primary-active;
      color: white;
    }
  }
}

.export-chat {
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
  display: flex;
  padding: 4px 4px;
  margin-right: 1rem;
  align-items: center;
  gap: 8px;
  position: sticky;
  top: 0;
  background-color: white;
  align-self: flex-end;

  &:hover {
    color: $color-primary-active;
  }
}

.send-message-form {
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-self: flex-end;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(70ch, 90%);
  margin: auto;
  flex-grow: 1;
}
