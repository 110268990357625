@use '@carbon/type';

.cds--css-grid {
  padding: 0;
}

$default-icon-size: 32px;
$default-logo-size: 3rem;

h1 {
  font-size: type.type-scale(7) !important;
  line-height: $default-logo-size !important;
}

h2 {
  font-size: type.type-scale(5) !important;
  line-height: $default-icon-size !important;
}

.cds--modal-close {
  padding: 0.75rem !important;
  border: 2px solid transparent !important;
  background-color: transparent !important;
  block-size: 3rem !important;
  cursor: pointer !important;
  inline-size: 3rem !important;
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
}

.cds--tooltip-trigger__wrapper {
  .cds--tag {
    border: none;
  }
}
